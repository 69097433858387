<template>
  <div>
    <gmap-map
      :zoom="13"
      :center="center"
      :options="{ fullscreenControl: false, mapTypeControl: false }"
      style="width:100%;  height: 400px;"
    >
      <gmap-marker
        v-for="(m, index) in pins"
        :key="index"
        :position="m.position"
        :label="m.label"
        @click="center=m.position"
      />
    </gmap-map>
  </div>
</template>
<script>
export default {
  name: 'GoogleMap',
  props: {
    pins: { type: Array, default: () => ([]) },
  },
  data() {
    return {
      center: {
        lat: 38.650932,
        lng: -9.0701008,
      },
      locPlaces: [
      ],
      existingPlace: null,
    }
  },

  mounted() {
    this.locateGeoLocation()
  },

  methods: {
    initMarker(loc) {
      this.existingPlace = loc
    },
    locateGeoLocation() {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        }
      })
    },
  },
}
</script>
