<template>
  <view-base ref="base">
    <GoogleMap :pins="driversPositions" class="mb-2" />
    <div class="mb-2">
      <h4><b>Estafetas</b> ({{ drivers.length }})</h4>
      <div class="row">
        <advanced-table
          :columns="columnsDrivers"
          :values="drivers"
          :paginated="false"
          :show-filter="false"
          :on-select-item="onDriverSelected"
          :filter-case-sensitive="false"
          empty-message="Nenhum estafeta ativo hoje"
        />
      </div>
    </div>
    <div class="mb-2">
      <h4><b>Restaurantes</b> ({{ stores.length - 1 }})</h4>
      <div class="row">
        <advanced-table
          :columns="columnsStores"
          :values="stores"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          empty-message="Nenhum restaurantes com entrega hoje"
        />
      </div>
    </div>
    <div class="mb-2">
      <h4><b>Entregas em preparo </b> ({{ newOrders.length }})</h4>
      <div class="row">
        <advanced-table
          :columns="columnsNewOrders"
          :values="newOrders"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          empty-message="Nenhuma entrega em preparo"
        />
      </div>
    </div>
    <div class="mb-2">
      <h4><b>Entregas em curso </b> ({{ ordersNotDelivered.length }})</h4>
      <div class="row">
        <advanced-table
          :columns="columnsNotDelivered"
          :values="ordersNotDelivered"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          empty-message="Nenhuma entrega em curso"
        />
      </div>
    </div>
    <div>
      <h4><b>Últimas entregas realizadas </b> ({{ orders.length }})</h4>
      <div class="row">
        <advanced-table
          :columns="columnsDelivered"
          :values="orders"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          :on-select-item="onOrderSelected"
          empty-message="Nenhuma entrega realizada hoje"
        />
      </div>
    </div>
  </view-base>
</template>

<style scoped>
.card {
  width: 300px;
  display: inline-block;
  vertical-align: middle;
  background: #F0F0F0;
  border-radius: 3px 3px 4px 4px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid white;

  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
</style>

<script>
import Ripple from 'vue-ripple-directive'

import axios from 'axios'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import ViewBase from '@/views/ViewBase.vue'
import GoogleMap from '@/views/components/GoogleMap.vue'
import { getUserData } from '@/auth/utils'

const AdvancedTable = () => import('@core/components/AdvancedTableComponent.vue')

export default {
  components: {
    ViewBase,
    AdvancedTable,
    GoogleMap,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      orders: [],
      stores: [],
      newOrders: [],
      ordersNotDelivered: [],
      drivers: [],
      driversPositions: [],
      userRole: getUserData().role.code,
      columnsNewOrders: [
        {
            title: 'Restaurante',
            name: 'store',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
          title: 'Código',
          name: 'code',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
        },
        {
          title: 'Cliente',
          name: 'eaterName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
        },
        {
            title: 'Hora',
            name: 'formatDate',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
      ],
      columnsStores: [
        {
            title: 'Restaurante',
            name: 'store',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
          title: 'Entregas',
          name: 'orders',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
        },
      ],
      columnsNotDelivered: [
        {
            title: 'Restaurante',
            name: 'store',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
          title: 'Código',
          name: 'code',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
        },
        {
          title: 'Cliente',
          name: 'eaterName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
        },
        {
            title: 'Motorista',
            name: 'driverName',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'Hora',
            name: 'formatDate',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
      ],
      columnsDelivered: [
        {
            title: 'Restaurante',
            name: 'store',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
          title: 'Código',
          name: 'code',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
        },
        {
          title: 'Cliente',
          name: 'eaterName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
        },
        {
            title: 'Motorista',
            name: 'driverName',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'Hora',
            name: 'formatDate',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'Tempo de entrega',
            name: 'deliveryTime',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'Valor',
            name: 'driverAmount',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
      ],
      columnsDrivers: [
        {
            title: 'Motorista',
            name: 'fullname',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'Hora',
            name: 'formatDate',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'Posição',
            name: 'geo',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla w-120px',
        },
      ],
      sortColunms: [],
      filteredSize: 0,
    }
  },
  computed: {
    lastMonday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate() - 7)
      return moment(today).format('DD/MM/YYYY')
    },
    lastSunday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate() - 1)
      return moment(today).format('DD/MM/YYYY')
    },
  },
  mounted() {
    if (!(getUserData().email === 'danilo@eatz.pt' || getUserData().email === 'gestor@eatz.pt')) {
      this.$router.push({ name: 'not-authorized' })
      return
    }
    this.loadData()
    window.DriverGeoUpdateEvent.listen('DriverGeoUpdateEvent', this.loadData)
  },
  created() {
  },
  methods: {
    loadData() {
      const self = this
      this.$refs.base.showLoading()
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/drivers/check`, useJwt.getHeader())
      .then(response => {
        self.stores = response.data?.stores ?? []
        self.orders = response.data?.orders ?? []
        self.newOrders = response.data?.newOrders ?? []
        self.ordersNotDelivered = response.data?.ordersNotDelivered ?? []
        self.drivers = response.data.drivers ?? []
        self.driversPositions = []

        self.orders.forEach((order, index) => {
          self.orders[index].driverName = `${order.driver.name} ${order.driver.lastname}`
          self.orders[index].formatDate = moment(order.updated_at).format('HH:mm')
          self.orders[index].link = `https://www.google.com/maps/search/${order.delivered_lat},${order.delivered_lon}`
          self.orders[index].deliveryTime = moment.duration(moment(order.delivered_at).diff(moment(order.pickup_at))).asMinutes().toFixed(0)
        })

        self.stores.push({
            store: '',
            store_id: null,
            orders: self.stores.reduce((acc, item) => acc + Number(item.orders), 0),
        })

        self.newOrders.forEach((order, index) => {
          self.newOrders[index].formatDate = moment(order.updated_at).format('HH:mm')
        })
        self.ordersNotDelivered.forEach((order, index) => {
          self.ordersNotDelivered[index].driverName = `${order.driver.name} ${order.driver.lastname}`
          self.ordersNotDelivered[index].formatDate = moment(order.updated_at).format('HH:mm')
          self.ordersNotDelivered[index].link = '-'
        })
        self.drivers.forEach((driver, index) => {
          self.drivers[index].fullname = `${driver.name} ${driver.lastname}`
          self.drivers[index].formatDate = moment(driver.geo_updated_at).format('HH:mm')
          self.drivers[index].link = `https://www.google.com/maps/search/${driver.geo_lat},${driver.geo_lon}`
          self.drivers[index].geo = `${driver.geo_lat},${driver.geo_lon}`
          self.driversPositions.push({ label: { text: self.drivers[index].fullname, fontSize: '12px' }, position: { lat: driver.geo_lat, lng: driver.geo_lon }, link: driver.link })
        })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    onDriverSelected(event, data) {
      window.open(data.link, '_blank')
    },
    onOrderSelected(event, data) {
      window.open(data.link, '_blank')
    },
  },
}
</script>
